import {css} from '@emotion/react'

import Card from '../../components/Card'
import CardGrid from '../../components/CardGrid'
import Page from '../../components/page/Page'
import LandingPageSection from '../../components/LandingPageSection'
import LinkButton from '../../components/LinkButton'

const getDataButtonCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 80px auto;

  a {
    color: white;
    width: 250px;
    font-size: 20px;
  }
`

const cardGridContainerCss = css`
  display: flex;
  overflow: visible;
  z-index: 1;
  margin-bottom: 105px;
  justify-content: center;
  align-items: center;
`

export default function LandingPage(): JSX.Element {
  return (
    <Page
      title={<>Training Data</>}
      subtitle={
        <>
          <p>High-quality, domain-specific training data for machine learning model training.</p>
          <p>Created by machine learning practitioners for machine learning practitioners.</p>
        </>
      }
    >
      <div css={cardGridContainerCss}>
        <CardGrid>
          <Card
            label="NLP"
            title="Wikimedia Dataset"
            description="Cleaned datasets from Wikidata and Wikipedia for knowledge intensive Natural Language Processing."
            primaryAction={{
              text: 'Read More & Download',
              href: `/datasets/wikimedia`,
            }}
          />
          <Card
            label="Speech Recognition"
            title="SPGISpeech Dataset"
            description="5,000 hours of professionally transcribed, fully formatted financial audio for acoustic modeling."
            primaryAction={{
              text: 'Read More & Download',
              href: `/datasets/spgispeech`,
            }}
          />
        </CardGrid>
      </div>
      <LandingPageSection title="Commercial Custom Training Data">
        <p>
          Need data for commercial purposes? Get in touch to discuss our custom curation process.
        </p>
        <div css={getDataButtonCss}>
          <LinkButton href="https://www.kensho.com/contact/solutions?product=data" external>
            Contact Us
          </LinkButton>
        </div>
      </LandingPageSection>
    </Page>
  )
}
