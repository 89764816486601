import {css} from '@emotion/react'

import {SignatureSubmissionResponse} from '../../api/submitSignature'
import SpinnerIcon from '../../components/SpinnerIcon'

export interface NetworkState {
  loading: boolean
  submissionResponse?: SignatureSubmissionResponse
  error?: Error
  uninitialized?: boolean
}

export const TALL_MOBILE_HEIGHT = '823px'

const dialogContentCss = css`
  padding: 15vh 50px;
  text-align: center;

  @media (max-height: ${TALL_MOBILE_HEIGHT}) {
    padding: 20vh 20px;
  }
`

const dialogTitleCss = css`
  font-size: 36px;
  font-weight: 800;
  margin: 0 0 30px;
  color: #50afc6;
`

const spinnerContainerCss = css`
  display: flex;
  justify-content: center;
  vertical-align: center;
`

interface AgreementResultProps {
  networkState: NetworkState
  children: React.ReactNode
}

export default function AgreementResult({
  networkState,
  children,
}: AgreementResultProps): JSX.Element | null {
  if (!networkState.loading && !(networkState.submissionResponse || networkState.error)) return null
  return networkState.loading ? (
    <div css={[dialogContentCss, spinnerContainerCss]}>
      <SpinnerIcon size={24} />
    </div>
  ) : (
    <section css={dialogContentCss}>
      <h2 css={dialogTitleCss}>{networkState.submissionResponse ? 'Success!' : 'Error'}</h2>
      {networkState.submissionResponse ? (
        children
      ) : (
        <p>There was an issue processing your request. Please try again later.</p>
      )}
    </section>
  )
}
