import {useState} from 'react'
import {css} from '@emotion/react'

const formCss = css`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 auto;

  align-items: center;

  label {
    flex: 1 0 200px;
    text-transform: uppercase;
    font-weight: 800;
    color: #50afc6;
    margin: 0 15px;
  }

  label > input {
    padding: 6px 12px;
    border-radius: 2px;
    outline: none;
    border: 1px solid #50afc6;
    width: 100%;
    font-weight: normal;

    &::placeholder {
      font-style: italic;
      color: rgba(0, 0, 0, 0.54);
    }

    &:invalid:not(.empty) {
      border: 1px solid red;
    }
  }
`

const submitButtonContainerCss = css`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const submitButtonCss = css`
  display: block;
  outline: none;
  border: none;
  color: white;
  background: #50afc6;
  width: 180px;
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none;
  margin: 0 15px;
`

const skipButtonCss = css`
  border: 1px solid #50afc6;
  color: #50afc6;
  background: white;
`

const signatureAgreementCss = css`
  margin: 20px 0;
  text-align: center;
  color: #50afc6;

  @media (max-width: 720px) {
    font-size: 12px;
  }
`

interface SignatureFields {
  name: string
  email: string
  institution: string
}

interface SignatureFormProps {
  submitSignature: (signature: SignatureFields) => void
  skippable?: boolean
  skipURL?: string
}

/** Style & functionality of the form that allows users to get access to Kensho data.
 * Posts user signature (name, email, institution) to BE for user access to data.
 */
export default function SignatureForm({
  submitSignature,
  skippable,
  skipURL,
}: SignatureFormProps): JSX.Element {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [institution, setInstitution] = useState('')

  if ((skippable && !skipURL) || (skipURL && !skippable)) {
    throw new Error('If providing skippable prop to SignatureForm, must provide skipURL as well!')
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value)
  }

  function handleInstitutionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setInstitution(event.target.value)
  }

  /** Called on submit. Defines what actions to take in the case of success/failure of signature submission. */
  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    submitSignature({
      name,
      email,
      institution,
    })
  }

  const formUntouched = email === '' && name === '' && institution === ''

  return (
    <>
      <p css={signatureAgreementCss}>
        If the terms above are acceptable, please complete the registration form details below.
      </p>
      <form css={formCss} onSubmit={handleSubmit}>
        <label htmlFor="name">
          Full Name
          <input
            value={name}
            className={formUntouched ? 'empty' : undefined}
            name="name"
            type="text"
            placeholder="Enter full name here"
            onChange={handleNameChange}
            required
          />
        </label>
        <label>
          Email
          <input
            value={email}
            className={formUntouched ? 'empty' : undefined}
            name="email"
            type="email"
            placeholder="Enter e-mail address here"
            onChange={handleEmailChange}
            required
          />
        </label>
        <label>
          Institution
          <input
            value={institution}
            className={formUntouched ? 'empty' : undefined}
            name="institution"
            type="text"
            placeholder="Enter affiliated institution here"
            onChange={handleInstitutionChange}
            required
          />
        </label>
        <div css={submitButtonContainerCss}>
          {skippable && (
            <a
              css={[submitButtonCss, skipButtonCss]}
              type="button"
              href={skipURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Skip
            </a>
          )}
          <input css={submitButtonCss} type="submit" />
        </div>
      </form>
    </>
  )
}
