import {css} from '@emotion/react'
import {Link} from 'react-router-dom'

import KenshoDatasetsLogo from '../../assets/logos/KenshoDatasetsLogo'
import ExternalLink from '../ExternalLink'

const headerCss = css`
  background-color: #ebf1f9;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const titleSectionCss = css`
  text-align: center;
  margin: auto;
  flex: 2;
`

const mainTitleCss = css`
  color: #50afc6;
  font-family: ff-good-headline-web-pro-con, Helvetica, Arial, sans-serif;
  font-size: 72px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  max-width: 700px;
  line-height: 1em;
  padding: 0 10px;

  @media (max-width: 720px) {
    font-size: 50px;
  }
`

const topHeaderCss = css`
  align-self: flex-start;
  padding: 0 55px;
  flex: 1 0 0px;
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    justify-content: center;
    padding: 0;
  }
`

const linkCss = css`
  text-decoration: none;
  color: #50afc6;
  flex: 1;
  text-align: right;
  text-transform: uppercase;

  @media (max-width: 720px) {
    display: none;
  }
`

const kenshoDatasetsLogoCss = css`
  flex: 0 0 250px;
`

interface HeaderProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
}

// The header to both the landing and agreement pages
export default function Header(props: HeaderProps): JSX.Element {
  const {title, subtitle} = props
  return (
    <header css={headerCss}>
      <div css={topHeaderCss}>
        <Link to="/">
          <KenshoDatasetsLogo css={kenshoDatasetsLogoCss} />
        </Link>
        <ExternalLink css={linkCss} to="https://kensho.com/">
          Kensho.com
        </ExternalLink>
      </div>
      <h1 css={[titleSectionCss, mainTitleCss]}>{title}</h1>
      <div css={titleSectionCss}>{subtitle}</div>
    </header>
  )
}
