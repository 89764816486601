import {css} from '@emotion/react'

const itemCss = css`
  margin-bottom: 36px;

  a {
    color: #50afc6;
    text-decoration: none;
  }
`

const titleCss = css`
  color: #50afc6;
  font-size: 36px;
  border-bottom: 3px solid #ebf1f9;
  font-weight: 800;
  margin: 0 0 8px;
`

export default function Section({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}): JSX.Element {
  return (
    <section css={itemCss}>
      <h1 css={titleCss}>{title}</h1>
      {children}
    </section>
  )
}
