import {IconX} from '@kensho/icons'
import {css} from '@emotion/react'

import Overlay from './Overlay'

type OverlayProps = JSX.LibraryManagedAttributes<
  typeof Overlay,
  React.ComponentProps<typeof Overlay>
>

interface DialogProps extends OverlayProps {
  /** Contents of the dialog. */
  children?: React.ReactNode
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string
  /** Callback to invoke when the dialog is closed. */
  onClose?: (event: React.MouseEvent) => void
  /** Title of the dialog. If omitted, the dialog will not render a header. */
  title?: string
}

const dialogContainerCss = css`
  background: white;
  border-radius: 3px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  max-width: 1350px;
  box-shadow: 0px 0px 24px 0px rgba(80, 175, 198, 1);
  width: 90%;
  position: relative;

  @media (max-width: 720px) {
    margin: 50px auto;
    max-height: 90%;
  }
`

const dialogHeaderCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(235, 241, 249);
  padding-top: 40px;
  border-radius: 3px 3px 0 0;
`

const headingCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ff-good-headline-web-pro-con;
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 48px;
  color: #50afc6;

  @media (max-width: 720px) {
    font-size: 30px;
  }
`

const buttonCss = css`
  border: none;
  background: none;
  padding: 0;
  color: #828287;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

/** Renders a dialog window on top of the entire application. */
export default function Dialog(props: DialogProps): JSX.Element {
  const {children, className, onClose, title, ...rest} = props

  return (
    <Overlay {...rest} onClose={onClose}>
      {children && (
        <div css={dialogContainerCss} className={className}>
          {title != null && (
            <div css={dialogHeaderCss}>
              <h2 css={headingCss}>{title}</h2>
              {onClose != null && (
                <button css={buttonCss} type="button" aria-label="close" onClick={onClose}>
                  <IconX size={32} />
                </button>
              )}
            </div>
          )}
          {children}
        </div>
      )}
    </Overlay>
  )
}
