import {memo} from 'react'
import {css} from '@emotion/react'
import {useFuse} from '@kensho/tacklebox'
import {CSSTransition} from 'react-transition-group'

import Portal from './Portal'

interface OverlayProps {
  /** Contents of the overlay. */
  children?: React.ReactNode
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string
  /** Whether to render a backdrop. */
  hasBackdrop?: boolean
  /** Whether the overlay is currently rendering visible content. */
  isOpen?: boolean
  /** Whether to mount immediately or when it is opened for the first time. */
  lazy?: boolean
  /** Callback to invoke when the overlay is closed. */
  onClose?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const overlayCss = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(5px);
  background: rgba(30, 35, 37, 0.5);
  z-index: 1;
  transition: opacity 0.3s;
  // appear from
  &.overlay-appear {
    opacity: 0;
  }
  // appear to
  &.overlay-appear-active {
    opacity: 1;
  }
  // enter from
  &.overlay-enter {
    opacity: 0;
  }
  // enter to
  &.overlay-enter-active {
    opacity: 1;
  }
  // exit from
  &.overlay-exit {
    opacity: 1;
  }
  // exit to
  &.overlay-exit-active {
    opacity: 0;
  }
`

const overlayBackdropCss = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

/** Renders content on top of the entire application. */
function Overlay(props: OverlayProps): JSX.Element | null {
  const {children, className, hasBackdrop = false, isOpen = false, lazy = true, onClose} = props

  const hasEverBeenOpen = useFuse(isOpen)

  if (lazy && !hasEverBeenOpen) return null
  return (
    <Portal>
      <CSSTransition
        appear
        in={isOpen}
        timeout={300}
        className={className}
        classNames="overlay"
        unmountOnExit
      >
        <div css={overlayCss}>
          {hasBackdrop && <div css={overlayBackdropCss} onMouseDown={onClose} />}
          {children}
        </div>
      </CSSTransition>
    </Portal>
  )
}

export default memo(Overlay)
