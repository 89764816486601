import 'promise-polyfill/src/polyfill'
import 'core-js/stable/object/assign'
import 'core-js/stable/set'
import 'core-js/stable/map'
import 'whatwg-fetch'
import {render} from 'react-dom'

import App from './App'

const rootElement = document.createElement('div')
document.body.appendChild(rootElement)

render(<App />, rootElement)
