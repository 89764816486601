export const COMPANY_NAME = 'Kensho Technologies, LLC.'
export const CURRENT_YEAR = new Date().getFullYear()

export const EMAILS = {
  INFO: 'data@kensho.com',
}

export const SOCIAL_URLS = {
  LINKEDIN: 'https://linkedin.com/company/kensh-technologies',
  TWITTER: 'https://twitter.com/kensho',
}
