export interface SignatureSubmissionResponse {
  download_uri: string
}

export interface UserSignature {
  name: string
  email: string
  institution: string
  dataset: 'scribe' | 'wikimedia'
}

/** Posts user data to back-end, returns status of request. */
export default function submitSignature(
  userSignature: UserSignature
): Promise<SignatureSubmissionResponse> {
  return fetch('/api/v1/sign/', {
    method: 'POST',
    body: JSON.stringify(userSignature),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'omit',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Error, receieved ${response.status} "${response.statusText}" after form submission`
      )
    }
    return response.json()
  })
}
