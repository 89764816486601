import {css} from '@emotion/react'
import {lazy, useState, useCallback, Suspense} from 'react'

import submitSignature from '../../api/submitSignature'
import Dialog from '../../components/Dialog'

import SignatureForm from './SignatureForm'
import AgreementResult, {NetworkState, TALL_MOBILE_HEIGHT} from './AgreementResult'

const Agreement = lazy(() => import(/* webpackPrefetch: true */ './Agreement'))

const baseDialogCss = css`
  max-width: 1350px;
  box-shadow: 0px 0px 24px 0px rgba(80, 175, 198, 1);
  width: 90%;
  height: 90%;
`

const responseDialogCss = css`
  margin-top: 25vh;
  height: 50%;

  @media (max-height: ${TALL_MOBILE_HEIGHT}) {
    margin-top: 10vh;
    height: 80%;
  }

  p {
    margin: 0;
  }
`

const formContentCss = css`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 20px 40px;
`

interface AgreementDialogProps {
  closeDialog: () => void
  isOpen: boolean
}

/** Displays the datasharing agreement and signature form in an overlay. */
export default function AgreementDialog({closeDialog, isOpen}: AgreementDialogProps): JSX.Element {
  const [networkState, setNetworkState] = useState<NetworkState>({
    loading: false,
    uninitialized: true,
  })

  const handleSubmitSignature = useCallback(({name, email, institution}) => {
    setNetworkState({loading: true, uninitialized: false})
    submitSignature({
      name,
      email,
      institution,
      dataset: 'scribe',
    })
      .then((submissionResponse) => setNetworkState({loading: false, submissionResponse}))
      .catch((error) => setNetworkState({loading: false, error}))
  }, [])

  const onClose = useCallback(() => {
    setNetworkState({loading: false, uninitialized: true})
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      css={[baseDialogCss, networkState.uninitialized ? {} : responseDialogCss]}
      onClose={onClose}
      isOpen={isOpen}
      title="Kensho + S&P Global Data Sharing Agreement"
      hasBackdrop
    >
      {networkState.uninitialized ? (
        <div css={formContentCss}>
          <Suspense fallback={null}>
            <Agreement />
          </Suspense>
          <SignatureForm submitSignature={handleSubmitSignature} />
        </div>
      ) : (
        <AgreementResult networkState={networkState}>
          <p>Your request for access to this dataset has been accepted.</p>
          <p>You’ll receive an email with instructions for downloading the data shortly.</p>
        </AgreementResult>
      )}
    </Dialog>
  )
}
