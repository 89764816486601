import {css} from '@emotion/react'

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-bottom-style: none;
  color: white;
  background: #50afc6;
  width: 380px;
  padding: 20px 36px;
  font-size: 28px;
  font-weight: normal;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0 20px 8px;
`

interface ButtonProps {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}

export default function Button({children, className, onClick}: ButtonProps): JSX.Element {
  return (
    <button type="button" css={buttonCss} onClick={onClick} className={className}>
      {children}
    </button>
  )
}
