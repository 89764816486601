import {css} from '@emotion/react'

const itemCss = css`
  margin: 36px;
  font-size: 16px;
  text-align: center;

  a {
    color: #50afc6;
    text-decoration: none;
  }
`

const titleCss = css`
  color: #444;
  font-size: 28.6px;
  font-weight: normal;
  font-family: ff-good-headline-web-pro-con, Helvetica, Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;

  ::after {
    display: block;
    margin: 0 auto;
    width: 80px;
    margin-top: 10px;
    content: '';
    border-top: 2px solid #50afc6;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    margin-bottom: 40px;
  }
`

/** a chimera of the datasets section and the kensho.com's section */
export default function LandingPageSection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}): JSX.Element {
  return (
    <section css={itemCss}>
      <h2 css={titleCss}>{title}</h2>
      {children}
    </section>
  )
}
