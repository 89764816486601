import {css} from '@emotion/react'

const buttonCss = css`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-bottom-style: none;
    color: white;
    background: #50afc6;
    width: 380px;
    padding: 20px 36px;
    font-size: 28px;
    font-weight: normal;
    cursor: pointer;
    border: none;
    outline: none;
    margin: 0 20px 8px;

    transition: 0.1s linear;
  }
  &:hover {
    opacity: 0.6;
  }
`

interface LinkButtonProps {
  children: React.ReactNode
  href: string
  external?: boolean
  className?: string
}

/**
 * Link styled exactly like a button to allow for proper link usage without conflicting styles.
 */
export default function LinkButton({
  children,
  href,
  external,
  className,
}: LinkButtonProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      type="button"
      css={buttonCss}
      target={external ? '_blank' : ''}
      rel={external ? 'noopener noreferrer' : ''}
      href={href}
      className={className}
    >
      {children}
    </a>
  )
}
