import {css} from '@emotion/react'

const cardGridCss = css`
  justify-content: center;
  max-width: 95vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 100px;

  @media (max-width: 1100px) {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export default function CardGrid({
  className,
  children,
}: {
  children: React.ReactNode
  className?: string
}): JSX.Element {
  return (
    <div css={cardGridCss} className={className}>
      {children}
    </div>
  )
}
