import {useEffect, useState} from 'react'

interface UseHasScrolledPageReturn {
  hasScrolledPage: boolean
  isAtTop: boolean
}

export default function useHasScrolledPage(offset: number): UseHasScrolledPageReturn {
  const [isAtTop, setIsAtTop] = useState(false)
  const [hasScrolledPage, setHasScrolledPage] = useState(false)

  useEffect(() => {
    function handleScroll(): void {
      setHasScrolledPage(
        (prevHasScrolled) =>
          prevHasScrolled || (document.documentElement.scrollTop || document.body.scrollTop) > 0
      )
      setIsAtTop((document.documentElement.scrollTop || document.body.scrollTop) <= offset)
    }
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [offset])

  return {hasScrolledPage, isAtTop}
}
