import {useState} from 'react'
import {css} from '@emotion/react'
import {IconDownload} from '@kensho/icons'

import Page from '../../components/page/Page'
import AgreementDialog from '../agreement/AgreementDialog'
import Button from '../../components/Button'

import LeftColumn from './LeftColumn'

const columnLayoutCss = css`
  max-width: 800px;
  margin: 0 auto;
`

const pageCss = css`
  max-width: 1500px;
  margin: 0 auto;
`

const buttonContainerCss = css`
  display: flex;
  justify-content: center;
  margin: 100px 0 200px;
`

const iconCss = css`
  margin-right: 12px;
  @media (max-width: 720px) {
    display: none;
  }
`

/** Page for scribe (audio transcription) dataset. Features information on the dataset and Kensho. */
export default function ScribePage(): JSX.Element {
  const [agreementDialogOpen, setAgreementDialogOpen] = useState(false)

  return (
    <Page
      css={pageCss}
      title="Kensho Audio Transcription Dataset"
      stickyNavbar
      navbarAction={() => setAgreementDialogOpen(true)}
    >
      <section css={columnLayoutCss}>
        <LeftColumn />
      </section>
      <div css={buttonContainerCss}>
        <Button onClick={() => setAgreementDialogOpen(true)}>
          <IconDownload size={32} css={iconCss} />
          Download the data
        </Button>
      </div>
      <AgreementDialog
        isOpen={agreementDialogOpen}
        closeDialog={() => setAgreementDialogOpen(false)}
      />
    </Page>
  )
}
