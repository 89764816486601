import {css} from '@emotion/react'
import {IconDownload} from '@kensho/icons'
import {Link} from 'react-router-dom'

import KenshoDatasetsLogo from '../../assets/logos/KenshoDatasetsLogo'
import useHasScrolledPage from '../../hooks/useHasScrolledPage'
import Button from '../Button'

const navbarContainerCss = css`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ebf1f9;
  padding: 15px 55px;
  display: flex;
  align-items: center;
  width: 100%;
  transform: translateY(-100%);
  z-index: 1;

  @media (max-width: 720px) {
    padding-left: 20px;
  }
`

// Used to add transition after load so slide-out animation doesn't happen immediately.
const navbarTransitionCss = css`
  transition: 200ms;
`

// Used to slide navbar on and off page.
const displayNavbarCss = css`
  transform: translateY(0);
`

const logoCss = css`
  width: 250px;
  display: flex;
`

const actionButtonCss = css`
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  font-size: 16px;
  width: auto;
  padding: 0 20px;
`

const actionButtonIconCss = css`
  margin-right: 5px;
  @media (max-width: 720px) {
    margin-right: 0;
  }
`

const actionButtonLabelCss = css`
  @media (max-width: 720px) {
    display: none;
  }
`

interface StickyNavbarProps {
  action?: () => void
}

export default function StickyNavbar(props: StickyNavbarProps): JSX.Element {
  const {action} = props
  const {hasScrolledPage, isAtTop} = useHasScrolledPage(300)

  return (
    <div
      css={[
        navbarContainerCss,
        hasScrolledPage && navbarTransitionCss,
        !isAtTop && displayNavbarCss,
      ]}
    >
      <Link to="/" css={logoCss}>
        <KenshoDatasetsLogo />
      </Link>
      {action && (
        <Button onClick={action} css={actionButtonCss}>
          <IconDownload css={actionButtonIconCss} />
          <span css={actionButtonLabelCss}>Download the Data</span>
        </Button>
      )}
    </div>
  )
}
