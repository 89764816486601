import LeftColumnContent from './LeftColumnContent'

export default function LeftColumn(props: {className?: string}): JSX.Element {
  const {className} = props
  return (
    <div className={className}>
      <LeftColumnContent />
    </div>
  )
}
