import {useState, useLayoutEffect} from 'react'
import {createPortal} from 'react-dom'

interface PortalProps {
  /** Contents of the portal. */
  children: React.ReactNode
}

const isBrowserEnv = typeof document === 'object'

/** Creates and returns a child element of the root body node. */
function useTopLevelElement(): HTMLDivElement | null {
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (isBrowserEnv) {
      const el = document.createElement('div')
      el.style.position = 'absolute'
      el.style.top = '0'
      el.style.left = '0'
      el.style.right = '0'
      document.body.appendChild(el)
      setElement(el)
      return () => {
        document.body.removeChild(el)
      }
    }
    return undefined
  }, [])

  return element
}

/** Renders its content in a child of the root body DOM node. */
export default function Portal(props: PortalProps): JSX.Element | null {
  const {children} = props

  const containerElement = useTopLevelElement()
  if (!containerElement) return null
  return createPortal(children, containerElement)
}
