interface ExternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  to: string
}

export default function ExternalLink(props: ExternalLinkProps): JSX.Element {
  const {children, to, ...rest} = props
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  )
}
