import {css} from '@emotion/react'
import {Link} from 'react-router-dom'

const btnLinkCss = css`
  & {
    border: 1px solid #50afc6;
    color: #50afc6;
    padding: 4px 10px;
    transition: 0.1s linear;

    &:hover {
      opacity: 0.6;
      background: transparent;
    }
  }
`

const primaryBtnLinkCss = css`
  ${btnLinkCss}
  && {
    color: white;
    background: #50afc6;
    width: 170px;
    margin: auto;
    font-size: 14px;

    &:hover {
      background: #50afc6;
    }
  }
`

const cardCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 280px;
  height: 410px;
  background: #fff;
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.15);
  padding: 10px;
  box-sizing: border-box;

  button {
    ${btnLinkCss}
  }
`

const mainContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 315px;
`

const labelCss = css`
  position: absolute;
  top: 12px;
  left: 15px;
  font-weight: 700;
  color: #999;
  font-size: 14px;
`

const titleCss = css`
  text-transform: uppercase;
  font-size: 28px;
  color: #50afc6;
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin: 87px auto 0px;
`

const descriptionCss = css`
  display: flex;
  align-items: center;
  padding: 10px 5% 0;
  flex: 1 0 auto;
  overflow: hidden;
  width: 100%;

  p {
    margin: 5px 0;
    text-align: center;
  }

  p,
  ul {
    font-size: 16px;
    line-height: 1.4;
    color: #5d5d5e;
    font-weight: 400;
  }

  ul {
    padding-left: 20px;

    li {
      margin: 5px 0;
    }
  }
`

const dividerCss = css`
  width: 90%;
  margin: 5px 0;
  border-bottom: 1px solid #50afc6;
`

const footerCss = css`
  width: 100%;
  text-align: center;
  margin: 15px 10px;

  button,
  a,
  a:visited {
    width: 150px;
    margin: auto;
    font-size: 14px;
  }

  a {
    line-height: 20px;
    display: inline-block;
    text-decoration: none;
  }
`

export default function Card({
  className,
  label,
  title,
  description,
  primaryAction,
}: {
  className?: string
  label?: string
  title?: string
  description: React.ReactNode
  primaryAction: {
    href: string
    text?: string
  }
}): JSX.Element {
  return (
    <div className={className} css={cardCss}>
      <div css={mainContentCss}>
        {label && <span css={labelCss}>{label}</span>}
        {title && <span css={titleCss}>{title}</span>}

        <div css={descriptionCss}>
          {typeof description === 'string' ? <p>{description}</p> : description}
        </div>
      </div>
      <div css={dividerCss} />
      <footer css={footerCss}>
        <Link css={primaryBtnLinkCss} to={primaryAction.href}>
          {primaryAction.text}
        </Link>
      </footer>
    </div>
  )
}
