import {css} from '@emotion/react'

import {LogoProps} from '../../types'

const logoCss = css`
  width: 300px;
`

export default function KenshoSPLogo(props: LogoProps): JSX.Element {
  const {className} = props
  return (
    <svg
      width="135"
      height="52"
      viewBox="0 0 135 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="KenshoS&PLogo"
      css={logoCss}
      className={className}
    >
      <path
        d="M13.7 0.410034L4.82 9.98003V0.410034H0V22.31H4.82V15.61L7.35 12.98L14.14 22.31H19.93L10.61 9.70003L19.52 0.410034H13.7Z"
        fill="#50AFC6"
      />
      <path
        d="M22.84 0.410034H39.36V4.70003H27.62V9.14003H37.95V13.42H27.62V18.02H39.51V22.31H22.84V0.410034Z"
        fill="#50AFC6"
      />
      <path
        d="M43.8 0.410034H48.2401L58.5 13.89V0.410034H63.26V22.31H59.16L48.55 8.39003V22.31H43.8V0.410034Z"
        fill="#50AFC6"
      />
      <path
        d="M67 19.12L69.85 15.71C71.6375 17.3569 73.9604 18.3017 76.39 18.37C78.39 18.37 79.55 17.58 79.55 16.3V16.24C79.55 15.02 78.79 14.39 75.13 13.46C70.72 12.33 67.88 11.11 67.88 6.76005V6.70005C67.88 2.70005 71.07 0.100048 75.54 0.100048C78.4909 0.0523269 81.3661 1.03547 83.67 2.88005L81.17 6.51005C79.5358 5.23057 77.5516 4.47736 75.48 4.35005C73.63 4.35005 72.66 5.20005 72.66 6.26005V6.32005C72.66 7.76005 73.6 8.23005 77.39 9.20005C81.83 10.36 84.33 11.95 84.33 15.77V15.83C84.33 20.18 81.01 22.62 76.33 22.62C72.896 22.6371 69.5755 21.3915 67 19.12Z"
        fill="#50AFC6"
      />
      <path
        d="M88.53 0.410034H93.35V9.07003H102.24V0.410034H107.06V22.31H102.24V13.52H93.35V22.31H88.53V0.410034Z"
        fill="#50AFC6"
      />
      <path
        d="M134.11 11.36C134.113 13.1585 133.684 14.9314 132.86 16.53L132.77 16.71L131.95 16.28L132.05 16.11C132.967 14.314 133.343 12.2908 133.134 10.2853C132.924 8.27974 132.138 6.37792 130.87 4.81003L129.51 5.93003L129.38 5.78003C128.636 4.89283 127.722 4.16396 126.691 3.63662C125.661 3.10928 124.535 2.79419 123.38 2.71003L123.24 4.94003H123.04H122.74C121.641 4.94049 120.56 5.2224 119.6 5.75892C118.64 6.29544 117.834 7.0687 117.258 8.00505C116.682 8.9414 116.355 10.0097 116.309 11.1081C116.262 12.2066 116.498 13.2986 116.993 14.2802C117.488 15.2619 118.226 16.1004 119.137 16.7159C120.048 17.3315 121.101 17.7036 122.197 17.7968C123.292 17.8901 124.393 17.7013 125.395 17.2485C126.397 16.7956 127.266 16.0939 127.92 15.21L128.04 15.05L129.85 16.34L131.28 17.34L132.03 17.87L131.92 18.03C130.782 19.61 129.257 20.8708 127.491 21.6916C125.725 22.5123 123.778 22.8654 121.836 22.7169C119.895 22.5684 118.024 21.9233 116.404 20.8436C114.783 19.7639 113.468 18.2858 112.583 16.5511C111.698 14.8165 111.274 12.8835 111.352 10.9379C111.429 8.99218 112.006 7.09909 113.025 5.4403C114.045 3.78151 115.474 2.4127 117.176 1.46528C118.877 0.517862 120.793 0.0236308 122.74 0.0300296H123.35H123.55C125.068 0.134468 126.549 0.542296 127.906 1.22937C129.262 1.91644 130.468 2.86879 131.45 4.03003L131.57 4.18003C133.207 6.19968 134.1 8.72039 134.1 11.32"
        fill="#50AFC6"
      />
      <path
        d="M6.08 48.86L5.53 47.23H2.15L1.59 48.86H0L3 40.36H4.71L7.71 48.86H6.08ZM3.84 42.26L2.62 45.84H5.05L3.84 42.26Z"
        fill="black"
      />
      <path
        d="M12.4901 48.86V45C12.4901 44.27 12.2501 43.82 11.4901 43.82C11.2496 43.8262 11.017 43.9063 10.8237 44.0495C10.6305 44.1926 10.486 44.3918 10.4101 44.62C10.2333 45.1545 10.1653 45.7189 10.2101 46.28V48.82H8.68005V42.63H9.94005L10.0401 43.43C10.2301 43.1148 10.5008 42.8559 10.8243 42.6802C11.1477 42.5044 11.5121 42.418 11.8801 42.43C12.1769 42.4093 12.4747 42.4552 12.7516 42.5642C13.0285 42.6732 13.2776 42.8426 13.4807 43.0601C13.6838 43.2776 13.8359 43.5376 13.9257 43.8214C14.0155 44.1051 14.0409 44.4052 14.0001 44.7V48.86H12.4901Z"
        fill="black"
      />
      <path
        d="M23.82 48.11C23.0767 48.7533 22.1111 49.08 21.1299 49.02C20.2048 49.0544 19.2998 48.7444 18.59 48.15C18.3107 47.8969 18.0873 47.5883 17.934 47.2439C17.7808 46.8995 17.7011 46.527 17.7 46.15H19.28C19.2984 46.5282 19.4591 46.8854 19.7299 47.15C20.1262 47.4809 20.635 47.6457 21.15 47.61C21.4148 47.6357 21.6822 47.6082 21.9363 47.5292C22.1905 47.4502 22.4263 47.3213 22.6299 47.15C22.7425 47.046 22.8312 46.9189 22.8899 46.7773C22.9486 46.6358 22.9759 46.4832 22.9699 46.33C22.9699 45.71 22.4899 45.42 21.9699 45.33C21.4499 45.24 20.65 45.2 20.09 45.1C19.648 45.0545 19.228 44.8845 18.8786 44.6099C18.5293 44.3353 18.265 43.9673 18.1164 43.5486C17.9677 43.1299 17.9408 42.6776 18.0388 42.2442C18.1368 41.8108 18.3556 41.4141 18.67 41.1C19.355 40.4937 20.2456 40.1718 21.16 40.2C22.0274 40.1697 22.8753 40.4618 23.54 41.02C23.7963 41.2495 24.0044 41.5277 24.1521 41.8385C24.2998 42.1492 24.384 42.4863 24.4 42.83H22.8099C22.7845 42.5164 22.6542 42.2205 22.44 41.99C22.0735 41.691 21.6129 41.5316 21.1399 41.54C20.636 41.5092 20.1403 41.678 19.76 42.01C19.6679 42.1055 19.5957 42.2183 19.5476 42.3419C19.4995 42.4655 19.4765 42.5974 19.4799 42.73C19.4745 42.9654 19.5615 43.1935 19.7222 43.3655C19.883 43.5375 20.1048 43.6396 20.34 43.65C20.88 43.73 21.64 43.79 22.19 43.87C22.8188 43.9031 23.4113 44.1752 23.8462 44.6306C24.2811 45.086 24.5257 45.6903 24.53 46.32C24.5422 46.6513 24.4854 46.9815 24.3632 47.2897C24.241 47.5979 24.056 47.8772 23.82 48.11Z"
        fill="#D61F2D"
      />
      <path
        d="M31.46 48.8801L30.86 48.2101C30.1882 48.783 29.3329 49.0953 28.45 49.0901C28.0932 49.116 27.7348 49.0688 27.3968 48.9514C27.0588 48.834 26.7483 48.6489 26.4844 48.4074C26.2204 48.1659 26.0085 47.873 25.8616 47.5468C25.7147 47.2206 25.6359 46.8678 25.63 46.5101C25.646 46.0211 25.7951 45.5457 26.0612 45.1352C26.3272 44.7246 26.7002 44.3944 27.14 44.1801C26.8693 43.9508 26.6488 43.6682 26.4922 43.3499C26.3356 43.0316 26.2464 42.6844 26.23 42.3301C26.2291 42.0328 26.2898 41.7386 26.4083 41.466C26.5268 41.1935 26.7006 40.9484 26.9186 40.7464C27.1366 40.5443 27.3941 40.3897 27.6749 40.2922C27.9557 40.1947 28.2537 40.1566 28.55 40.1801C28.8442 40.154 29.1407 40.1885 29.421 40.2816C29.7014 40.3746 29.9596 40.5242 30.1799 40.721C30.4001 40.9178 30.5777 41.1577 30.7015 41.4259C30.8254 41.694 30.893 41.9848 30.9 42.2801C30.8782 42.773 30.716 43.2495 30.4326 43.6535C30.1491 44.0574 29.7562 44.3719 29.3 44.5601L30.77 46.1701C30.9216 45.8408 31.0001 45.4826 31 45.1201C31 44.7401 31 44.3401 31 44.1201H33.2V45.3101H32.2C32.1993 45.9771 32.0234 46.6323 31.69 47.2101L33.26 48.9201L31.46 48.8801ZM28 45.1101C27.43 45.4501 27 45.7301 27 46.4501C27.003 46.6408 27.0459 46.8288 27.1257 47.002C27.2056 47.1752 27.3208 47.3298 27.4639 47.456C27.6069 47.5821 27.7748 47.677 27.9567 47.7345C28.1385 47.7921 28.3304 47.811 28.52 47.7901C28.7903 47.8138 29.0626 47.7787 29.3181 47.6872C29.5735 47.5957 29.8062 47.45 30 47.2601C29.3 46.5401 28.67 45.8301 28 45.1101ZM28.53 41.4201C28.4092 41.4103 28.2876 41.4259 28.1731 41.4658C28.0587 41.5058 27.9538 41.5693 27.8653 41.6521C27.7768 41.735 27.7066 41.8354 27.6592 41.947C27.6118 42.0586 27.5882 42.1788 27.59 42.3001C27.59 42.8601 28.04 43.3001 28.39 43.6101C28.92 43.3201 29.48 43.0501 29.48 42.3401C29.4858 42.2168 29.4657 42.0938 29.4208 41.9789C29.376 41.8639 29.3076 41.7597 29.2199 41.673C29.1322 41.5862 29.0272 41.5189 28.9118 41.4753C28.7964 41.4317 28.6731 41.4129 28.55 41.4201H28.53Z"
        fill="#D61F2D"
      />
      <path
        d="M37.85 45.82H36.05V48.82H34.52V40.32H37.85C39.8 40.32 40.85 41.41 40.85 43.04C40.85 44.67 39.8 45.82 37.85 45.82ZM37.79 41.72H36.05V44.46H37.79C38.79 44.46 39.29 43.91 39.29 43.1C39.29 42.29 38.82 41.72 37.79 41.72Z"
        fill="#D61F2D"
      />
      <path
        d="M50 48.86L49.82 47.93C49.5784 48.2775 49.2526 48.5579 48.873 48.745C48.4935 48.9322 48.0727 49.0199 47.65 49C47.1811 49.0254 46.7123 48.9491 46.2757 48.7765C45.839 48.604 45.4448 48.3391 45.12 48C44.7195 47.5384 44.4143 47.0021 44.2221 46.422C44.0298 45.8419 43.9543 45.2295 44 44.62C43.9331 44.0047 43.9964 43.3822 44.1859 42.7929C44.3754 42.2037 44.6869 41.661 45.1 41.2C45.7829 40.5531 46.6893 40.1949 47.63 40.2C48.0956 40.1896 48.5585 40.2728 48.9914 40.4445C49.4242 40.6163 49.8182 40.8732 50.15 41.2C50.6347 41.6372 50.9379 42.2402 51 42.89H49.37C49.3368 42.5961 49.2066 42.3217 49 42.11C48.8246 41.9304 48.6119 41.7916 48.3768 41.7035C48.1418 41.6153 47.8902 41.58 47.64 41.6C47.1202 41.6022 46.6216 41.8066 46.25 42.17C45.7408 42.8799 45.5063 43.7504 45.59 44.62C45.5144 45.4998 45.7516 46.378 46.26 47.1C46.6339 47.4501 47.1278 47.6434 47.64 47.64C47.874 47.6503 48.1076 47.612 48.3262 47.5277C48.5447 47.4434 48.7435 47.3148 48.91 47.15C49.1118 46.9266 49.2649 46.6637 49.3596 46.3779C49.4543 46.0921 49.4885 45.7897 49.46 45.49H47.46V44.21H51V48.86H50Z"
        fill="#D61F2D"
      />
      <path
        d="M54.28 48.94C54.0508 48.9704 53.8178 48.9481 53.5986 48.8749C53.3793 48.8017 53.1797 48.6795 53.0148 48.5175C52.8499 48.3555 52.7242 48.158 52.6471 47.9401C52.57 47.7222 52.5436 47.4896 52.57 47.26V40.19H54.08V47C54.08 47.36 54.17 47.6 54.68 47.6C54.82 47.6 54.85 47.6 54.94 47.6V48.86C54.7243 48.9147 54.5025 48.9416 54.28 48.94Z"
        fill="#D61F2D"
      />
      <path
        d="M60.79 48.0501C60.5135 48.3549 60.1762 48.5985 59.7999 48.7652C59.4236 48.9319 59.0166 49.0181 58.605 49.0181C58.1934 49.0181 57.7864 48.9319 57.4101 48.7652C57.0338 48.5985 56.6965 48.3549 56.42 48.0501C55.9444 47.3755 55.6891 46.5704 55.6891 45.7451C55.6891 44.9197 55.9444 44.1146 56.42 43.4401C56.6965 43.1352 57.0338 42.8916 57.4101 42.7249C57.7864 42.5582 58.1934 42.472 58.605 42.472C59.0166 42.472 59.4236 42.5582 59.7999 42.7249C60.1762 42.8916 60.5135 43.1352 60.79 43.4401C61.2656 44.1146 61.5209 44.9197 61.5209 45.7451C61.5209 46.5704 61.2656 47.3755 60.79 48.0501ZM59.55 44.1801C59.427 44.054 59.2801 43.9538 59.1178 43.8853C58.9555 43.8169 58.7811 43.7817 58.605 43.7817C58.4289 43.7817 58.2545 43.8169 58.0922 43.8853C57.9299 43.9538 57.783 44.054 57.66 44.1801C57.3248 44.6313 57.1681 45.1904 57.22 45.7501C57.171 46.3095 57.3274 46.8676 57.66 47.3201C57.9111 47.5698 58.2508 47.71 58.605 47.71C58.9591 47.71 59.2989 47.5698 59.55 47.3201C59.8853 46.8686 60.0452 46.3106 60 45.7501C60.0481 45.1892 59.8879 44.6303 59.55 44.1801Z"
        fill="#D61F2D"
      />
      <path
        d="M67.56 48.48C67.133 48.8257 66.5994 49.0129 66.05 49.01C65.7119 49.0312 65.3739 48.9662 65.0678 48.821C64.7618 48.6758 64.4975 48.4553 64.3 48.18L64.2 48.86H62.9V40.21H64.4V43.08C64.6117 42.8756 64.8626 42.7162 65.1376 42.6114C65.4126 42.5065 65.706 42.4584 66 42.47C66.5545 42.4525 67.097 42.6333 67.53 42.98C67.9131 43.3294 68.212 43.761 68.4043 44.2425C68.5965 44.7241 68.6771 45.2429 68.64 45.76C68.6761 46.2654 68.5977 46.7725 68.4107 47.2434C68.2237 47.7143 67.9329 48.137 67.56 48.48ZM66.75 44.22C66.6336 44.0654 66.4824 43.9405 66.3087 43.8554C66.1349 43.7702 65.9435 43.7273 65.75 43.73C64.57 43.73 64.33 44.84 64.33 45.73C64.3032 46.0366 64.3371 46.3454 64.4297 46.6388C64.5224 46.9322 64.672 47.2045 64.87 47.44C65.0044 47.5536 65.1602 47.6392 65.3281 47.6917C65.496 47.7442 65.6728 47.7627 65.8479 47.7459C66.0231 47.7291 66.1931 47.6775 66.348 47.5941C66.503 47.5106 66.6396 47.397 66.75 47.26C67.0454 46.8067 67.1858 46.2699 67.15 45.73C67.1858 45.1965 67.0452 44.6659 66.75 44.22Z"
        fill="#D61F2D"
      />
      <path
        d="M74.8101 49C74.5659 49.0021 74.3294 48.9148 74.1452 48.7545C73.961 48.5942 73.8418 48.3721 73.8101 48.13C73.597 48.4322 73.3113 48.6758 72.9792 48.8384C72.6471 49.0009 72.2794 49.0771 71.9101 49.06C71.621 49.1324 71.3195 49.1393 71.0275 49.0801C70.7354 49.021 70.4603 48.8974 70.2222 48.7183C69.9841 48.5392 69.789 48.3092 69.6511 48.045C69.5133 47.7809 69.4363 47.4892 69.4256 47.1915C69.4149 46.8937 69.4709 46.5973 69.5895 46.324C69.7081 46.0506 69.8862 45.8072 70.1109 45.6116C70.3357 45.4159 70.6012 45.2729 70.8883 45.1931C71.1753 45.1132 71.4766 45.0985 71.7701 45.15L73.5501 45V44.6C73.5501 44.01 73.3501 43.6 72.5501 43.6C71.7501 43.6 71.4401 43.91 71.4101 44.49H69.9101C69.9901 43.09 71.1101 42.49 72.5001 42.49C72.9731 42.4258 73.4542 42.5144 73.8734 42.7429C74.2925 42.9713 74.6277 43.3277 74.8301 43.76C74.9632 44.1592 75.0209 44.5797 75.0001 45V47.32C75.0001 47.61 75.0901 47.71 75.3401 47.71C75.4033 47.716 75.4669 47.716 75.5301 47.71V48.81C75.3104 48.9341 75.0624 48.9995 74.8101 49ZM73.5501 46.13L72.0001 46.21C71.7733 46.2043 71.5531 46.2865 71.3854 46.4392C71.2177 46.592 71.1155 46.8037 71.1001 47.03C71.1051 47.1404 71.1323 47.2487 71.1802 47.3484C71.2281 47.448 71.2956 47.5369 71.3788 47.6098C71.4619 47.6826 71.5589 47.7379 71.664 47.7723C71.769 47.8068 71.8799 47.8196 71.9901 47.81C72.1915 47.836 72.3961 47.8182 72.5899 47.7577C72.7838 47.6973 72.9623 47.5958 73.1133 47.46C73.2643 47.3242 73.3842 47.1574 73.4647 46.971C73.5453 46.7846 73.5846 46.583 73.5801 46.38L73.5501 46.13Z"
        fill="#D61F2D"
      />
      <path
        d="M78.4301 48.94C78.201 48.9704 77.9679 48.9481 77.7487 48.8749C77.5295 48.8017 77.3298 48.6795 77.165 48.5175C77.0001 48.3555 76.8743 48.158 76.7972 47.9401C76.7201 47.7222 76.6938 47.4896 76.7201 47.26V40.19H78.2401V47C78.2401 47.36 78.3201 47.6 78.8401 47.6C78.9701 47.6 79.0001 47.6 79.0901 47.6V48.86C78.8746 48.9159 78.6527 48.9428 78.4301 48.94Z"
        fill="#D61F2D"
      />
      <path
        d="M88.3399 48C88.0193 48.3194 87.6386 48.5722 87.2199 48.7439C86.8011 48.9155 86.3525 49.0026 85.8999 49C85.4516 49.0042 85.007 48.9177 84.5929 48.7459C84.1788 48.5741 83.8036 48.3204 83.4899 48C83.0927 47.526 82.7985 46.9744 82.6264 46.3804C82.4542 45.7864 82.4077 45.163 82.4899 44.55C82.4077 43.937 82.4542 43.3137 82.6264 42.7196C82.7985 42.1256 83.0927 41.574 83.4899 41.1C83.804 40.7801 84.1792 40.5268 84.5932 40.355C85.0073 40.1832 85.4517 40.0965 85.8999 40.1C86.3527 40.0964 86.8016 40.1829 87.2205 40.3546C87.6394 40.5263 88.02 40.7797 88.3399 41.1C88.822 41.6203 89.1083 42.292 89.1499 43H87.5699C87.5402 42.6677 87.4073 42.3531 87.1899 42.1C87.028 41.9212 86.8281 41.7808 86.6049 41.6891C86.3817 41.5974 86.1409 41.5567 85.8999 41.57C85.6564 41.5689 85.4152 41.6181 85.1915 41.7144C84.9678 41.8108 84.7664 41.9523 84.5999 42.13C84.0988 42.8572 83.8851 43.7444 83.9999 44.62C83.8932 45.4812 84.1028 46.3519 84.5899 47.07C84.7564 47.2478 84.9578 47.3893 85.1815 47.4856C85.4052 47.582 85.6464 47.6312 85.8899 47.63C86.1315 47.6414 86.3727 47.5988 86.5958 47.5054C86.8189 47.412 87.0184 47.2701 87.1799 47.09C87.3976 46.8217 87.53 46.4943 87.5599 46.15H89.1399C89.0875 46.8388 88.8059 47.4901 88.3399 48Z"
        fill="black"
      />
      <path
        d="M95.18 48.05C94.9052 48.356 94.5691 48.6007 94.1935 48.7683C93.8179 48.9358 93.4113 49.0224 93 49.0224C92.5888 49.0224 92.1821 48.9358 91.8065 48.7683C91.4309 48.6007 91.0948 48.356 90.82 48.05C90.3444 47.3755 90.0891 46.5704 90.0891 45.745C90.0891 44.9197 90.3444 44.1146 90.82 43.44C91.0948 43.134 91.4309 42.8893 91.8065 42.7218C92.1821 42.5543 92.5888 42.4677 93 42.4677C93.4113 42.4677 93.8179 42.5543 94.1935 42.7218C94.5691 42.8893 94.9052 43.134 95.18 43.44C95.6556 44.1146 95.9109 44.9197 95.9109 45.745C95.9109 46.5704 95.6556 47.3755 95.18 48.05ZM93.94 44.18C93.8196 44.0519 93.6738 43.9502 93.512 43.8814C93.3502 43.8125 93.1759 43.778 93 43.78C92.8144 43.7707 92.6289 43.8016 92.4563 43.8707C92.2837 43.9397 92.128 44.0452 92 44.18C91.6648 44.6312 91.5081 45.1904 91.56 45.75C91.511 46.3095 91.6674 46.8676 92 47.32C92.1305 47.4509 92.2867 47.5532 92.4589 47.6204C92.6311 47.6875 92.8154 47.7181 93 47.71C93.1749 47.7116 93.3482 47.6779 93.5098 47.6108C93.6713 47.5438 93.8176 47.4449 93.94 47.32C94.2796 46.8707 94.44 46.3111 94.39 45.75C94.4429 45.1887 94.2823 44.6281 93.94 44.18Z"
        fill="black"
      />
      <path
        d="M104.65 48.86V45C104.65 44.23 104.44 43.79 103.65 43.79C102.86 43.79 102.42 44.69 102.42 45.47V48.87H101V45C101 44.23 100.79 43.79 100 43.79C99.21 43.79 98.76 44.69 98.76 45.47V48.87H97.3V42.63H98.54L98.66 43.46C98.8396 43.1488 99.0996 42.8917 99.4128 42.7158C99.7261 42.5398 100.081 42.4515 100.44 42.46C100.803 42.4342 101.166 42.5152 101.484 42.6928C101.802 42.8705 102.061 43.1371 102.23 43.46C102.423 43.1379 102.699 42.8745 103.031 42.6983C103.362 42.522 103.735 42.4396 104.11 42.46C104.392 42.4489 104.674 42.4995 104.934 42.6084C105.195 42.7173 105.428 42.8817 105.619 43.0903C105.809 43.2988 105.952 43.5465 106.037 43.8159C106.122 44.0853 106.147 44.3699 106.11 44.65V48.85L104.65 48.86Z"
        fill="black"
      />
      <path
        d="M112.48 48.52C112.056 48.8434 111.534 49.0127 111 49C110.701 49.0182 110.401 48.9733 110.12 48.8682C109.84 48.7631 109.584 48.6002 109.37 48.39V51.23H107.87V42.63H109.16L109.26 43.31C109.461 43.0382 109.726 42.8201 110.031 42.6753C110.336 42.5305 110.673 42.4634 111.01 42.48C111.56 42.4754 112.094 42.6629 112.52 43.01C112.902 43.3478 113.202 43.7686 113.396 44.2401C113.59 44.7116 113.673 45.2212 113.64 45.73C113.674 46.2529 113.587 46.7766 113.386 47.2605C113.185 47.7444 112.875 48.1754 112.48 48.52ZM111.68 44.24C111.565 44.0836 111.414 43.9567 111.24 43.8698C111.066 43.7829 110.874 43.7384 110.68 43.74C109.51 43.74 109.27 44.86 109.27 45.74C109.27 46.62 109.51 47.74 110.68 47.74C110.874 47.7428 111.065 47.6998 111.239 47.6147C111.413 47.5295 111.564 47.4046 111.68 47.25C111.93 46.7897 112.062 46.274 112.062 45.75C112.062 45.226 111.93 44.7104 111.68 44.25V44.24Z"
        fill="black"
      />
      <path
        d="M119.74 49.0001C119.486 49.0174 119.234 48.9371 119.037 48.7754C118.84 48.6137 118.712 48.3828 118.68 48.1301C118.468 48.4308 118.184 48.6735 117.853 48.836C117.523 48.9984 117.158 49.0755 116.79 49.0601C116.501 49.133 116.2 49.1405 115.908 49.0821C115.615 49.0236 115.34 48.9007 115.102 48.7222C114.863 48.5437 114.668 48.3142 114.529 48.0504C114.391 47.7867 114.313 47.4953 114.302 47.1976C114.29 46.9 114.345 46.6035 114.463 46.3299C114.581 46.0564 114.758 45.8125 114.982 45.6163C115.207 45.4201 115.472 45.2764 115.758 45.1957C116.045 45.115 116.346 45.0994 116.64 45.1501L118.48 45.0201V44.6001C118.48 44.0101 118.27 43.6001 117.42 43.6001C116.73 43.6001 116.32 43.9101 116.28 44.4901H114.83C114.92 43.0901 116.03 42.4901 117.42 42.4901C117.894 42.4268 118.376 42.5157 118.797 42.7439C119.217 42.9721 119.555 43.3279 119.76 43.7601C119.899 44.1649 119.96 44.5925 119.94 45.0201V47.3401C119.94 47.6301 120.04 47.7301 120.29 47.7301H120.48V48.8301C120.252 48.9506 119.997 49.0092 119.74 49.0001ZM118.48 46.1301L116.89 46.2501C116.664 46.2468 116.445 46.3298 116.278 46.4821C116.111 46.6344 116.008 46.8446 115.99 47.0701C115.995 47.1805 116.022 47.2888 116.07 47.3884C116.118 47.488 116.185 47.5769 116.269 47.6498C116.352 47.7227 116.449 47.778 116.554 47.8124C116.659 47.8468 116.77 47.8596 116.88 47.8501C117.082 47.8776 117.288 47.861 117.483 47.8013C117.678 47.7416 117.858 47.6402 118.01 47.5043C118.162 47.3683 118.283 47.201 118.364 47.0138C118.445 46.8266 118.485 46.624 118.48 46.4201V46.1301Z"
        fill="black"
      />
      <path
        d="M125.55 48.86V45C125.55 44.27 125.31 43.82 124.55 43.82C124.31 43.8262 124.077 43.9063 123.884 44.0495C123.69 44.1926 123.546 44.3919 123.47 44.62C123.293 45.1545 123.225 45.7189 123.27 46.28V48.82H121.77V42.63H123L123.1 43.43C123.29 43.1148 123.561 42.856 123.884 42.6802C124.208 42.5044 124.572 42.4181 124.94 42.43C125.232 42.4119 125.525 42.4582 125.797 42.5656C126.07 42.673 126.315 42.8389 126.517 43.0516C126.718 43.2643 126.87 43.5186 126.962 43.7965C127.054 44.0745 127.084 44.3692 127.05 44.66V48.82L125.55 48.86Z"
        fill="black"
      />
      <path
        d="M131.33 49.93C131.231 50.3677 130.988 50.7595 130.64 51.0425C130.292 51.3254 129.859 51.483 129.41 51.49C129.022 51.491 128.635 51.4405 128.26 51.34V50.09C128.535 50.1579 128.817 50.1948 129.1 50.2C129.254 50.2079 129.407 50.1667 129.537 50.0823C129.666 49.9978 129.765 49.8745 129.82 49.73L130.18 48.85L127.72 42.63H129.36L131 47.14L132.56 42.63H134.09L131.33 49.93Z"
        fill="black"
      />
      <path d="M78.2299 36.44H17.6899" stroke="#231F20" strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  )
}
