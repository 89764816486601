import {css} from '@emotion/react'

import {LogoProps} from '../../types'

const logoCss = css`
  width: 300px;
`

export default function KenshoDatasetsLogo(props: LogoProps): JSX.Element {
  const {className} = props
  return (
    <svg
      id="KenshoDatasetsLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2915.6438 257.4782"
      css={logoCss}
      className={className}
    >
      <defs>
        <symbol id="Kensho_Logo" data-name="Kensho Logo" viewBox="0 0 95 16.092">
          <path
            fill="#4faec5"
            d="M0,.29H3.4128V7.0714L9.7065.29h4.1222L7.5128,6.8715l6.6041,8.9309h-4.1L5.2082,9.1983,3.4128,11.06V15.802H0Z"
          />
          <path
            fill="#4faec5"
            d="M16.1778.29h11.701V3.3264h-8.31V6.4732H26.882V9.5087H19.5683v3.2582H27.99v3.0355H16.1778Z"
          />
          <path
            fill="#4faec5"
            d="M31.0261.29h3.1473l7.2683,9.5514V.29h3.369V15.8024H41.9075l-7.5132-9.861v9.861H31.0257V.29Z"
          />
          <path
            fill="#4faec5"
            d="M47.4694,13.5426l2.0165-2.4159a7.1182,7.1182,0,0,0,4.6322,1.8841c1.3955,0,2.2374-.5541,2.2374-1.4628v-.0441c0-.8645-.5318-1.3077-3.1242-1.9723C50.1063,8.7335,48.09,7.8693,48.09,4.7889V4.7444C48.09,1.93,50.35.0684,53.5187.0684a8.9824,8.9824,0,0,1,5.7622,1.9723L57.5078,4.6116a7.2805,7.2805,0,0,0-4.0332-1.5291c-1.3078,0-1.9946.5982-1.9946,1.3518v.0441c0,1.02.6646,1.3519,3.3464,2.0387,3.1468.82,4.92,1.95,4.92,4.6541v.0441c0,3.0805-2.3495,4.8087-5.6955,4.8087A9.87,9.87,0,0,1,47.4694,13.5426Z"
          />
          <path
            fill="#4faec5"
            d="M62.7164.29h3.4127V6.4287h6.2937V.29h3.4128V15.8024H72.4228V9.5755H66.1291v6.2269H62.7164Z"
          />
          <path
            fill="#4faec5"
            d="M95,8.0458a7.9952,7.9952,0,0,1-.8848,3.6657c-.021.0405-.0433.0822-.0643.1231l-.5768-.3007c.0223-.04.0446-.0822.0656-.1231a7.39,7.39,0,0,0-.8321-8.0057l-.9674.7941c-.0287-.0361-.0583-.0729-.0874-.1073a6.1362,6.1362,0,0,0-4.2469-2.1706l-.0976,1.5756c-.0457-.0044-.0935-.0068-.1392-.0085-.0728-.004-.1441-.0056-.2165-.0056a4.5632,4.5632,0,1,0,3.6714,7.2744c.0279-.0377.0546-.0753.0809-.113l1.283.9184,1.0188.7289.5281.3776c-.0259.0377-.0542.077-.0809.113A8.0449,8.0449,0,1,1,86.9534,0c.1449,0,.29.004.433.0125.0462.0017.0923.0041.14.0081A8.04,8.04,0,0,1,93.1216,2.885c.0308.0368.06.0712.0878.1072A8.0114,8.0114,0,0,1,95,8.0458Z"
          />
        </symbol>
      </defs>
      <title>Kensho Datasets Logo</title>
      <use
        width="95"
        height="16.0924"
        transform="translate(-0.1667) scale(16)"
        href="#Kensho_Logo"
      />
      <path
        fill="#848484"
        d="M1603.7983,17.65h75.6c73.9195,0,124.9917,51.0718,124.9917,117.2632v.6719c0,66.1924-51.0722,117.2637-124.9917,117.2637h-75.6Zm75.9356,228.4791c69.2153,0,117.2632-48.72,117.2632-110.2071v-.6728c0-61.8233-48.3838-110.8794-117.9351-110.8794H1611.19V246.1288Z"
      />
      <path
        fill="#848484"
        d="M1829.45,206.4813v-.6719c0-35.28,32.9277-53.4238,74.9277-53.4238,23.5205,0,46.3682,4.3672,63.168,10.416V148.6893c0-40.6562-24.1924-59.8076-63.5039-59.8076-20.832,0-38.3037,5.7119-55.7754,14.1113l-2.6885-6.0478c18.4805-9.0713,36.96-14.7832,58.8-14.7832,23.1836,0,41.3282,6.0478,53.76,18.4794,10.416,10.4161,16.4639,26.88,16.4639,47.712V252.8485h-7.0557V213.201c-7.0557,19.4883-32.2559,43.68-75.2637,43.68C1859.3545,256.8807,1829.45,239.745,1829.45,206.4813Zm138.0957-15.12V169.8573c-17.8076-6.72-37.2959-10.752-62.832-10.752-37.9678,0-67.8711,15.4561-67.8711,46.3672v.6729c0,29.5673,26.207,44.0156,55.7754,44.0156C1937.6416,250.161,1967.5459,221.2645,1967.5459,191.3612Z"
      />
      <path
        fill="#848484"
        d="M2025.1348,209.8407V91.2332h-26.544V84.85h26.544V26.7215h7.0556V84.85H2094.35v6.3837H2032.19V209.1688c0,25.2,12.0957,39.9844,35.9522,39.9844a58.1028,58.1028,0,0,0,25.5351-6.0489v7.3926a61.1443,61.1443,0,0,1-25.5351,5.376C2040.2539,255.8729,2025.1348,239.0731,2025.1348,209.8407Z"
      />
      <path
        fill="#848484"
        d="M2111.3809,206.4813v-.6719c0-35.28,32.9277-53.4238,74.9277-53.4238,23.52,0,46.3682,4.3672,63.168,10.416V148.6893c0-40.6562-24.1924-59.8076-63.5039-59.8076-20.8321,0-38.3038,5.7119-55.7754,14.1113l-2.6885-6.0478c18.4805-9.0713,36.96-14.7832,58.8-14.7832,23.1836,0,41.3281,6.0478,53.76,18.4794,10.416,10.4161,16.4638,26.88,16.4638,47.712V252.8485h-7.0556V213.201c-7.0557,19.4883-32.2559,43.68-75.2637,43.68C2141.2852,256.8807,2111.3809,239.745,2111.3809,206.4813Zm138.0957-15.12V169.8573c-17.8077-6.72-37.2959-10.752-62.8321-10.752-37.9677,0-67.8711,15.4561-67.8711,46.3672v.6729c0,29.5673,26.2071,44.0156,55.7754,44.0156C2219.5723,250.161,2249.4766,221.2645,2249.4766,191.3612Z"
      />
      <path
        fill="#848484"
        d="M2285.8975,231.0086l4.3681-5.7119c18.4795,15.4561,41.999,23.8565,63.8389,23.8565,30.24,0,53.0879-15.4561,53.0879-37.2959v-.6719c0-25.8721-27.8877-33.2647-55.1035-40.9922-34.2715-9.7441-58.8-18.48-58.8-45.0234v-.6729c0-23.5195,23.1836-42.6719,58.4638-42.6719,18.8155,0,40.6563,6.3848,58.4639,18.8164l-4.0322,6.0479c-16.4639-11.76-36.6241-18.1436-54.4317-18.1436-30.5761,0-51.0713,15.792-51.0713,35.6153v.6719c0,22.1767,24.1914,30.5761,53.4229,38.6406,33.2646,9.0713,60.4805,18.48,60.4805,47.375v.6728c0,26.2071-25.8721,44.3516-60.8164,44.3516C2330.249,255.8729,2304.7129,246.8006,2285.8975,231.0086Z"
      />
      <path
        fill="#848484"
        d="M2440.6553,168.85v-.6719c0-49.7285,33.9355-87.36,80.64-87.36,53.0879,0,77.2793,46.7041,77.2793,88.7041v1.3438H2448.3828c1.3438,48.3838,35.28,79.2959,76.6074,79.2959,25.8721,0,48.72-15.12,62.16-33.9365l5.7119,4.0322c-14.4482,20.832-38.64,36.624-68.208,36.624C2478.623,256.8807,2440.6553,222.9452,2440.6553,168.85Zm150.1914-4.3682c-1.3447-38.3037-25.5362-76.9434-69.8877-76.9434-41.6641,0-70.8965,32.9278-72.5762,76.9434Z"
      />
      <path
        fill="#848484"
        d="M2639.0254,209.8407V91.2332h-26.543V84.85h26.543V26.7215h7.0566V84.85h62.1592v6.3837H2646.082V209.1688c0,25.2,12.0957,39.9844,35.9512,39.9844a58.1038,58.1038,0,0,0,25.5361-6.0489v7.3926a61.1456,61.1456,0,0,1-25.5361,5.376C2654.1455,255.8729,2639.0254,239.0731,2639.0254,209.8407Z"
      />
      <path
        fill="#848484"
        d="M2726.9863,231.0086l4.3682-5.7119c18.4795,15.4561,41.999,23.8565,63.8389,23.8565,30.24,0,53.0878-15.4561,53.0878-37.2959v-.6719c0-25.8721-27.8876-33.2647-55.1035-40.9922-34.2715-9.7441-58.8-18.48-58.8-45.0234v-.6729c0-23.5195,23.1836-42.6719,58.4639-42.6719,18.8164,0,40.6562,6.3848,58.4639,18.8164l-4.0323,6.0479c-16.4638-11.76-36.624-18.1436-54.4316-18.1436-30.5762,0-51.0723,15.792-51.0723,35.6153v.6719c0,22.1767,24.1924,30.5761,53.4239,38.6406,33.2646,9.0713,60.48,18.48,60.48,47.375v.6728c0,26.2071-25.872,44.3516-60.8164,44.3516C2771.3379,255.8729,2745.8018,246.8006,2726.9863,231.0086Z"
      />
    </svg>
  )
}
