import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'

import LandingPage from './pages/landing/LandingPage'
import ScribePage from './pages/scribe/ScribePage'
import WikimediaPage from './pages/wikimedia/WikimediaPage'

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/datasets/wikimedia" element={<WikimediaPage />} />
        <Route path="/datasets/spgispeech" element={<ScribePage />} />
        <Route path="/datasets/scribe" element={<Navigate to="/datasets/spgispeech" replace />} />
        {/* TODO: replace with 404 page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  )
}
