import {css} from '@emotion/react'

import {COMPANY_NAME, CURRENT_YEAR, EMAILS, SOCIAL_URLS} from '../../constants'
import KenshoSPGLogo from '../../assets/logos/kensho-spg-white.svg'
import EmailLink from '../EmailLink'
import ExternalLink from '../ExternalLink'

/** Site footer stolen from the Kensho public website.
 * Majority of CSS and Footer component comes from two files in the kensho/public repo:
 * src/components/Page/Footer.tsx
 * src/components/Page/styles.css
 * Remaining styles come from higher level CSS */
const footerCss = css`
  background-color: #141516;
  color: #888;
  font-size: 13px;
`

const footerContainerCss = css`
  margin: 0 auto;
  padding: 64px 128px 84px 128px;
  max-width: 1440px;

  @media (max-width: 1280px) {
    padding: 64px 64px 84px 64px;
  }
`

const contentCss = css`
  display: flex;
  line-height: 2;

  a {
    text-decoration: none;
    transition: all 0.2s;
    color: #888;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    &:hover {
      color: white;
      opacity: 0.6;
    }
  }

  h4 {
    margin: 0px 0px 6px 0px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: white;
    white-space: pre;
    line-height: 1.4;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`

const resourcesCss = css`
  text-align: right;
  flex: 1;

  @media (max-width: 1150px) {
    margin-top: 50px;
    text-align: center;

    a {
      display: inline-block;
      padding: 12px 6px;
      margin: 0 2px;
    }
  }
`

const officesCss = css`
  display: flex;

  @media (max-width: 1150px) {
    justify-content: space-between;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    text-align: center;
  }
`

const officeCss = css`
  &:not(:last-child) {
    margin-right: 40px;

    @media (max-width: 1150px) {
      margin-right: 0;
    }

    @media (max-width: 720px) {
      margin-bottom: 30px;
    }
  }
`

const footerTitleCss = css`
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (max-width: 720px) {
    text-align: center;
  }
`
const spgFooterLogoCss = css`
  width: 150px;
`

const copyrightCss = css`
  margin-top: 25px;
  text-align: center;
`

interface OfficeProps {
  city: string
  name: string
  address: string
}

function Office(props: OfficeProps): JSX.Element {
  const {address, city, name} = props
  return (
    <div css={officeCss}>
      <h4>{name}</h4>
      <div>{address}</div>
      <div>{city}</div>
    </div>
  )
}

/** The footer to both the landing and agreement pages. Stolen directly from
 * the Kensho public website. */
export default function Footer(): JSX.Element {
  return (
    <footer css={footerCss}>
      <div css={footerContainerCss}>
        <div css={footerTitleCss}>
          <img
            src={KenshoSPGLogo}
            css={spgFooterLogoCss}
            alt="Kensho, a company of S&amp;P Global"
          />
        </div>
        <div css={contentCss}>
          <div css={officesCss}>
            <Office
              name={'Harvard Square\n+ AI Lab'}
              address="44 Brattle St"
              city="Cambridge, MA 02138"
            />
            <Office name="New York City" address="55 Water Street" city="New York, NY 10041" />
            <Office name="Washington D.C." address="Tysons Corner" city="McLean, VA 22102" />
          </div>
          <div css={resourcesCss}>
            <div>
              <ExternalLink to="https://kensho.com/contact">Contact</ExternalLink>
              <ExternalLink to="https://kensho.com/privacy">Privacy Policy</ExternalLink>
              <ExternalLink to="https://kensho.com/terms-of-use">Web Terms</ExternalLink>
              <ExternalLink to="https://kensho.com/terms-of-service">Service Terms</ExternalLink>
            </div>
            <div>
              <EmailLink email={EMAILS.INFO}>Email</EmailLink>
              <ExternalLink to={SOCIAL_URLS.LINKEDIN}>LinkedIn</ExternalLink>
              <ExternalLink to={SOCIAL_URLS.TWITTER}>Twitter</ExternalLink>
            </div>
          </div>
        </div>
        <div css={copyrightCss}>
          Copyright &copy; {CURRENT_YEAR} {COMPANY_NAME} Kensho marks are the property of{' '}
          {COMPANY_NAME} All rights reserved.
        </div>
      </div>
    </footer>
  )
}
