import {css} from '@emotion/react'
import {useState, useCallback} from 'react'

import submitSignature from '../../api/submitSignature'
import Dialog from '../../components/Dialog'
import ExternalLink from '../../components/ExternalLink'
import LinkButton from '../../components/LinkButton'
import AgreementResult, {NetworkState} from '../agreement/AgreementResult'
import SignatureForm from '../agreement/SignatureForm'

const dialogContentCss = css`
  padding: 40px;
`

const textContentCss = css`
  margin: 0 auto 40px;
  max-width: 800px;
`

const getDataButtonCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`

interface InfoDialogProps {
  isOpen: boolean
  closeDialog: () => void
}

export default function InfoDialog(props: InfoDialogProps): JSX.Element {
  const {isOpen, closeDialog} = props

  const [networkState, setNetworkState] = useState<NetworkState>({
    loading: false,
    uninitialized: true,
  })

  const handleSubmitSignature = useCallback(({name, email, institution}) => {
    setNetworkState({loading: true, uninitialized: false})
    submitSignature({
      name,
      email,
      institution,
      dataset: 'wikimedia',
    })
      .then((submissionResponse) => setNetworkState({loading: false, submissionResponse}))
      .catch((error) => setNetworkState({loading: false, error}))
  }, [])

  const onClose = useCallback(() => {
    setNetworkState({loading: false, uninitialized: true})
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog onClose={onClose} isOpen={isOpen} title="Optional Info" hasBackdrop>
      <div css={dialogContentCss}>
        {networkState.uninitialized ? (
          <>
            <p css={textContentCss}>
              We like to know who is using what we are sharing. This way we can build communities
              around data usage. If you&apos;d like to help us, submit your information below. All
              submissions are governed by our{' '}
              <ExternalLink
                to="https://kensho.com/privacy"
                style={{
                  color: '#50afc6',
                  textDecoration: 'none',
                }}
              >
                Privacy Policy
              </ExternalLink>
              , and we will never share or sell any data.
            </p>
            <SignatureForm
              submitSignature={handleSubmitSignature}
              skippable
              skipURL="https://www.kaggle.com/kenshoresearch/kensho-derived-wikimedia-data"
            />
          </>
        ) : (
          <AgreementResult networkState={networkState}>
            <p>Your email has been registered to receive updates on the Wikimedia dataset.</p>
            <p>Thank you for your interest!</p>
            <div css={getDataButtonCss}>
              <LinkButton
                href="https://www.kaggle.com/kenshoresearch/kensho-derived-wikimedia-data"
                external
              >
                Get the Data
              </LinkButton>
            </div>
          </AgreementResult>
        )}
      </div>
    </Dialog>
  )
}
