import {Global, css} from '@emotion/react'

import KenshoSPLogo from '../../assets/logos/KenshoS&PLogo'

import Header from './Header'
import Footer from './Footer'
import StickyNavbar from './StickyNavbar'
import ScrollToTop from './ScrollToTop'

/** Most global CSS is taken from kensho/public */
const globalPageCss = css`
  body {
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font: 16px/1.6 proxima-nova, Helvetica, Arial, sans-serif;
  }

  ::selection {
    background: hsla(0, 0%, 70.6%, 0.2);
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
`

const kenshoSPLogoCss = css`
  margin: 80px 60px 60px 0;

  @media (max-width: 720px) {
    margin: 30px 0;
  }
`

const logoContainerCss = css`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 720px) {
    justify-content: center;
  }
`
const contentCss = css`
  padding: 20px 80px 0;

  @media (max-width: 720px) {
    padding: 20px;
  }
`

interface PageProps {
  children: React.ReactNode
  className?: string
  title: React.ReactNode
  subtitle?: React.ReactNode
  stickyNavbar?: boolean
  navbarAction?: () => void
}

export default function Page(props: PageProps): JSX.Element {
  const {children, className, title, subtitle, stickyNavbar, navbarAction} = props
  return (
    <>
      <Global styles={globalPageCss} />
      <ScrollToTop />
      <Header title={title} subtitle={subtitle} />
      {stickyNavbar && <StickyNavbar action={navbarAction} />}
      <main className={className}>
        <div css={logoContainerCss}>
          <KenshoSPLogo css={kenshoSPLogoCss} />
        </div>
        <div css={contentCss}>{children}</div>
      </main>
      <Footer />
    </>
  )
}
